import './HeaderBanner.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Breadcrumb } from '../Breadcrumb';
import { Container } from '../Container';
import { HeaderBannerContentSection } from '../contentTypes';

export interface HeaderBannerPros extends HeaderBannerContentSection {
  body?: string;
  className?: string;
  title?: string;
}

export const HeaderBanner = ({
  asset,
  assetBackgroundColor,
  body,
  className,
  showBreadcrumb,
  textBlock,
  title,
}: HeaderBannerPros) => {
  return (
    <div className="header-banner-page">
      <Container
        className={classnames('position-relative', className, {
          'container-bg': assetBackgroundColor,
        })}
        style={assetBackgroundColor ? { backgroundColor: assetBackgroundColor } : {}}
      >
        <b2x.Row>
          <b2x.Col className="px-0 px-xl-2"> {!assetBackgroundColor && <b2x.Asset {...asset} fluid />}</b2x.Col>
        </b2x.Row>

        <b2x.Div className="text-block-coverer">
          <b2x.Container className="text-block-container">
            <b2x.Row className={classnames('h-100', { 'justify-content-center': textBlock?.alignment === 'center' })}>
              <b2x.Col
                className="text-block-resetter"
                size={textBlock?.alignment === 'center' ? { md: 10 } : { md: 8, xl: 6 }}
              >
                <b2x.Div
                  className={classnames(
                    'text-block',
                    { start: textBlock?.alignment === 'start' },
                    { center: textBlock?.alignment === 'center' },
                    { end: textBlock?.alignment === 'end' },
                    { 'mobile-bottom': textBlock?.verticalMobileAlignment === 'end' },
                    { 'mobile-center': textBlock?.verticalMobileAlignment === 'center' }
                  )}
                  paddingX={{ xl: 5, xs: 2 }}
                >
                  {showBreadcrumb && <Breadcrumb hiddenLastItem />}
                  <h1
                    className={classnames(
                      'title text-uppercase fw-bold mb-3 my-md-2 lh-1',
                      `text-${textBlock?.colorTitle}`
                    )}
                  >
                    {title ? b2x.formatHtml(title) : b2x.formatHtml(textBlock?.title)}
                  </h1>
                  {!textBlock?.isBodyOutOfAsset && (
                    <b2x.Div
                      className={classnames('body h4 primary-font', `text-${textBlock?.colorBody}`, {
                        'd-none d-md-block': textBlock?.hideBodyMobile,
                      })}
                    >
                      {body ? b2x.formatHtml(body) : b2x.formatHtml(textBlock?.body)}
                    </b2x.Div>
                  )}
                </b2x.Div>
              </b2x.Col>
            </b2x.Row>
          </b2x.Container>
        </b2x.Div>
      </Container>
      {textBlock?.isBodyOutOfAsset && (
        <Container>
          <b2x.Row className="justify-content-center">
            <b2x.Col size={{ lg: 10, xl: 8, xs: 12 }}>
              <b2x.Div
                className={classnames('body h4 primary-font text-secondary text-center', {
                  'd-none d-md-block': textBlock.hideBodyMobile,
                })}
                marginY={3}
              >
                {body ? b2x.formatHtml(body) : b2x.formatHtml(textBlock.body)}
              </b2x.Div>
            </b2x.Col>
          </b2x.Row>
        </Container>
      )}
    </div>
  );
};
