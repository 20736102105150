export const en = {
  misc: {
    addToCart: 'Add to cart',
    code: 'Code',
    storeName: 'De Cecco',
    youSearchedFor: 'Your search:',
  },
  product: {
    freeShippingFrom: 'Free shipping from {{freeShippingFromAmount}}',
  },
  welcome: 'Welcome to De Cecco',
};
