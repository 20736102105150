import { Resource } from '../i18n';

export const de: Resource = {
  form: {
    addToCartForm: {
      buttons: {
        submit: 'In den Warenkorb legen',
      },
      productVariants: 'Format',
    },
  },
  header: {
    sendTo: 'Land {{country}} | {{locale}}',
  },
  misc: {
    addToCart: 'Hinzufügen',
    code: 'Code',
    storeName: 'De Cecco',
    youSearchedFor: 'Sie haben gesucht:',
  },
  product: {
    freeShippingFrom: 'Kostenloser Versand ab {{freeShippingFromAmount}}',
    relatedProduct: {
      title: 'Das könnte Ihnen auch gefallen',
    },
    size: 'Format',
  },
  welcome: 'Willkommen im De Cecco Store',
};
