import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppStaticContext } from '../AppContext';

export interface PageProps extends b2x.PageProps {
  backgroundImage?: b2x.contentTypes.ImageContentSection;
  greyBackground?: boolean;
  headerCheckout?: boolean;
  noPaddingBottom?: boolean;
  noPaddingTop?: boolean;
}

export const Page = ({
  backgroundImage,
  children,
  className,
  greyBackground = false,
  headerCheckout = false,
  noPaddingBottom = false,
  noPaddingTop = false,

  ...otherProps
}: PageProps) => {
  const { pageReady } = b2x.useAppContext();
  const { setGreyBackgroundPage, setHeaderCheckout } = useAppStaticContext();

  React.useEffect(() => {
    pageReady && setHeaderCheckout(headerCheckout);
    pageReady && setGreyBackgroundPage(greyBackground);
  }, [pageReady, headerCheckout, setHeaderCheckout, setGreyBackgroundPage, greyBackground]);

  return (
    <b2x.Page {...otherProps} className={classnames(className, 'h-100 ', { 'bg-gray-100': greyBackground })}>
      <div className="Page h-100 position-relative">
        {backgroundImage && (
          <div className="position-absolute h-100 w-100">
            <b2x.ImageAsBackgroundFromContent {...backgroundImage} />
          </div>
        )}
        <div
          className={classnames(
            'position-relative h-100',
            'py-3 py-xl-5',
            { 'bg-lighter': greyBackground },
            { 'pt-0 pt-xl-0': noPaddingTop },
            { 'pb-0 pb-xl-0': noPaddingBottom }
          )}
        >
          {children}
        </div>
      </div>
    </b2x.Page>
  );
};
