import { Resource } from '../i18n';

export const it: Resource = {
  form: {
    addToCartForm: {
      buttons: {
        submit: 'Aggiungi al carrello',
      },
      productVariants: 'Formato',
    },
  },
  header: {
    sendTo: 'Paese: {{country}} | {{locale}}',
  },
  misc: {
    addToCart: 'Aggiungi',
    code: 'Codice',
    storeName: 'De Cecco',
    youSearchedFor: 'Hai cercato:',
  },
  product: {
    freeShippingFrom: 'Spedizioni gratuite a partire da {{freeShippingFromAmount}}',
    relatedProduct: {
      title: 'Ti potrebbe piacere anche',
    },
    size: 'Formato',
  },
  welcome: 'Benvenuto su store De Cecco!',
};
