import { b2x } from '@b2x/react/src';
import React from 'react';

import { BannerA } from '../BannerA';
import { HomePageContentType } from '../contentTypes';
import { Newsletter } from '../Newsletter';
import { SectionA } from '../SectionA';
import { SectionB } from '../SectionB';
import { SliderA } from '../slider/SliderA';
import { Page } from './Page';
import { usePopUpNewsletterModal } from './PopupNewsletterModal';

export interface HomePageProps {}

export const HomePage = (props: HomePageProps) => {
  const page = b2x.usePage<HomePageContentType>({
    secondStep: {
      populate: {
        products: b2x.appConfig.api?.productTilePopulate,
      },
    },
  });

  const { pageReady } = b2x.useAppContext();

  const [PopupNewsLetterModal, showPopupNewsLetterModal] = usePopUpNewsletterModal({
    onClose: () => {
      const modalClosedTimes = (b2x.storage.getNumber('homePageNewsletterModalClosedTimes', true) ?? 0) + 1;
      b2x.storage.setNumber('homePageNewsletterModalClosedTimes', modalClosedTimes, true);
    },
  });

  React.useEffect(() => {
    const newsletterConsent = b2x.storage.getBoolean('newsletterConsent', true);
    if (newsletterConsent) {
      return;
    }

    const modalClosedTimes = b2x.storage.getNumber('homePageNewsletterModalClosedTimes', true) ?? 0;
    if (modalClosedTimes < 2 && pageReady) {
      b2x.wait(10000 * (modalClosedTimes + 1)).then(showPopupNewsLetterModal);
    }
  }, [pageReady, showPopupNewsLetterModal]);

  return (
    <Page noPaddingBottom noPaddingTop>
      {PopupNewsLetterModal}
      <b2x.ContentSectionByShippingCountry contentSections={page?.content?.body.topSliderByCountry}>
        {(contentSection) => <SliderA {...contentSection} />}
      </b2x.ContentSectionByShippingCountry>
      <b2x.ContentSectionByShippingCountry contentSections={page?.content?.body.monthsOfferByCountry}>
        {(contentSection) => <SectionA {...contentSection} />}
      </b2x.ContentSectionByShippingCountry>
      <b2x.ContentSectionByShippingCountry contentSections={page?.content?.body.preferredByCountry}>
        {(contentSection) => <SectionB {...contentSection} />}
      </b2x.ContentSectionByShippingCountry>
      <b2x.ContentSectionByShippingCountry contentSections={page?.content?.body.glutenFreeByCountry}>
        {(contentSection) => <SectionA {...contentSection} />}
      </b2x.ContentSectionByShippingCountry>
      <b2x.ContentSectionByShippingCountry contentSections={page?.content?.body.promoContentByCountry}>
        {(contentSection) => <BannerA {...contentSection} />}
      </b2x.ContentSectionByShippingCountry>
      <Newsletter />
    </Page>
  );
};
