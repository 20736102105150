import { Resource } from '../i18n';

export const es: Resource = {
  form: {
    addToCartForm: {
      buttons: {
        submit: 'Añadir al carrito',
      },
      productVariants: 'Formato',
    },
  },
  header: {
    sendTo: 'País: {{country}} | {{locale}}',
  },
  misc: {
    addToCart: 'Añadir',
    code: 'Código',
    storeName: 'De Cecco',
    youSearchedFor: 'Has buscado:',
  },
  product: {
    freeShippingFrom: 'Envíos gratuitos a partir de {{freeShippingFromAmount}}',
    relatedProduct: {
      title: 'También te podría gustar',
    },
    size: 'Formato',
  },
  welcome: '¡Bienvenidos a la tienda De Cecco!',
};
