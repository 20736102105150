import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { BoxBContentSection } from './contentTypes';

export interface BoxBProps extends BoxBContentSection {}

export const BoxB = ({ background, cta, image, percentage, title }: BoxBProps) => {
  return (
    <b2x.Div className="rounded-3 text-center" style={{ backgroundImage: `url(${background?.url})` }}>
      {percentage && (
        <div className="secondary-font" style={{ fontSize: 82, fontWeight: 'bold' }}>
          {percentage}%
        </div>
      )}
      {title && <h2 className={classnames('text-uppercase fw-bold mb-4 px-3', { 'pt-5': !percentage })}>{title}</h2>}
      {cta && <b2x.CtaFromContent {...cta} />}
      {image?.src && (
        <b2x.NotResponsiveImageFromContent
          {...image.src}
          fluid
          style={{
            left: image.offsetX,
            position: 'relative',
            top: image.offsetY,
            transform: image.scale ? `scale(${image.scale})` : undefined,
          }}
        />
      )}
    </b2x.Div>
  );
};
