import './NewsletterSubscribePage.scss';

import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { NewsletterPageContentType } from '../contentTypes';
import { NewsletterForm } from '../forms/NewsletterForm';
import { Page } from './Page';

export interface NewsletterSubscribePageProps {}

export const NewsletterSubscribePage = () => {
  const content = b2x.useContent<NewsletterPageContentType>('NEWSLETTER_PAGE_CONTENT');

  return (
    <Page className="newsletter-subscribe-page">
      <Container>
        <div className="d-flex flex-wrap newsletter overflow-hidden">
          {content?.body.asset && (
            <div className="left-box w-100">
              <b2x.Asset {...content.body.asset} fluid />
            </div>
          )}
          <div className="right-box w-100">
            <div className="h-100  px-5 py-3 py-lg-0 d-lg-flex align-items-center">
              <div className="text-white text-start">
                {content?.body.title && <h1 className="pb-3">{b2x.formatHtml(content.body.title)}</h1>}
                {content?.body.content && <div className="pb-3 fw-normal">{b2x.formatHtml(content.body.content)}</div>}
                <NewsletterForm className="pb-5 pb-lg-0" emailFieldStyle="textInput" source="website-modal" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Page>
  );
};
