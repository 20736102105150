import './ProductTile.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Button, ButtonVariant } from './Button';
import { CustomSkuRadio } from './CustomSkuRadio';
import { t } from './i18n/i18n';
import { IconName, IconSize } from './Icon';

export type ProductTileProps = b2x.ProductTileProps;

export const ProductTile = (props: ProductTileProps) => {
  return <b2x.ProductTile className={'card border-0 my-3'} {...props} />;
};

export type ProductTileBasicInfoProps = b2x.ProductTileBasicInfoProps;

export const ProductTileBasicInfo = ({
  handleLinkClick,
  imageFormat,
  product,
  productsPerRow,
  sku,
}: ProductTileBasicInfoProps) => {
  const { discountPercentage } = b2x.usePrice(product, sku?.id);

  const alternativeImage =
    product.alternativeImages?.length && product.alternativeImages.length > 0 && product.alternativeImages[0];

  return (
    <div className="product-tile-basic-info">
      <div className="product-tile-image-container position-relative mb-3 bg-gray-100">
        <b2x.router.Link onClick={handleLinkClick} style={{ display: 'block' }} to={product.url}>
          <b2x.EqualHeightElement name={'product-tile-image'}>
            <b2x.Image
              alt={product.name}
              aspectRatio={b2x.appConfig.productImageAspectRatio}
              className="d-block"
              fluid
              format={imageFormat}
              src={sku?.image ? sku.image.src : product.image?.src}
            />
            {typeof alternativeImage === 'object' && (
              <b2x.Image
                alt={product.name}
                aspectRatio={b2x.appConfig.productImageAspectRatio}
                className="alternative-image"
                fluid
                format={imageFormat}
                {...alternativeImage}
                loading={undefined}
              />
            )}
          </b2x.EqualHeightElement>
        </b2x.router.Link>
        {b2x.appConfig.enableWishlist && (
          <div className="wishlist-action-container position-absolute end-0 top-0 py-1 px-2 px-md-3 py-md-2">
            <b2x.WishlistButtonHelper product={product} sku={sku}>
              {({ handleWishlistButtonClick, inWishlist }) => (
                <Button
                  className="text-light-blue"
                  iconEnd={{ name: inWishlist ? 'wishlist-full' : 'wishlist', size: 25 }}
                  onClick={handleWishlistButtonClick}
                  size="large"
                  type="button"
                  variant="blank"
                />
              )}
            </b2x.WishlistButtonHelper>
          </div>
        )}
        {discountPercentage && (
          <div className="discount-percentage position-absolute top-0 start-0 py-1 py-md-2">
            <span className="bg-red text-white fw-bold lh-1 py-1 ps-3 pe-2 rounded-end">{`-${b2x.formatFloatPercentage(
              discountPercentage
            )}%`}</span>
          </div>
        )}
      </div>
      <div className="mb-2">
        <b2x.EqualHeightElement name={`product-title-row-${productsPerRow}`}>
          <h4 className="m-0 mb-1 product-title">
            <b2x.router.Link
              className="text-decoration-none text-blue fw-bold"
              onClick={handleLinkClick}
              to={product.url}
            >
              {b2x.formatHtml(product.name)}
            </b2x.router.Link>
          </h4>
          {sku && sku.name !== product.name && (
            <div className="extra-small mb-0 text-blue fw-semi-bold m-0">{sku.name}</div>
          )}
        </b2x.EqualHeightElement>
      </div>
    </div>
  );
};

export type ProductTileExtraInfoProps = b2x.ProductTileExtraInfoProps;

export const ProductTileExtraInfo = (props: ProductTileExtraInfoProps) => (
  <b2x.ProductTileExtraInfo customComponent={CustomProductTileExtraInfo} {...props} />
);

const CustomProductTileExtraInfo = ({
  fieldsHelper,
  priceHelper,
  productsPerRow,
  selectedSku,
}: ProductTileExtraInfoProps) => {
  return (
    <div className="product-tile-extra-info">
      <b2x.EqualHeightElement name={`product-tile-variant-${productsPerRow}`}>
        <div>
          <b2x.FormGroup
            {...fieldsHelper.productVariants.formGroup}
            className="product-variant bg-powder-light d-inline-block"
            label={undefined}
          >
            <div>
              <div className="product-variant-container d-flex flex-wrap gap-2 small">
                {fieldsHelper.productVariants.formFields.map((formField) => (
                  <b2x.Radio key={formField.productVariant.id} {...formField.radio} inline>
                    {formField.productVariant.skus?.map((sku) => (
                      <CustomSkuRadio
                        key={sku.id}
                        label={`${sku.measurement?.value} ${sku.measurement?.measurementUnit}`}
                        sku={sku}
                      />
                    ))}
                  </b2x.Radio>
                ))}
              </div>
            </div>
          </b2x.FormGroup>
        </div>
      </b2x.EqualHeightElement>

      <b2x.EqualHeightElement name={`product-tile-price-block-${productsPerRow}`}>
        {priceHelper && (
          <div className={classnames({ 'd-none d-lg-block': selectedSku?.state === 'AVAILABLE' }, 'mb-1')}>
            <div className="hstack flex-wrap">
              <div className="me-2">
                <b2x.PriceBlock
                  classNames={{ specialPrice: 'fw-bold text-red' }}
                  gap={2}
                  hiddenDiscountPercentage
                  priceHelper={priceHelper}
                />
              </div>
              
              {priceHelper.perUnit?.price && (
                <div className="extra-small">
                  {`(${t('misc.pricePerUnit', {
                    measurementUnit: priceHelper.perUnit.measurement,
                    price: b2x.formatCurrency(priceHelper.perUnit.price),
                  })})`}
                </div>
              )}
            </div>
          </div>
        )}

        {selectedSku?.state === 'AVAILABLE' ? (
          <div className="d-flex align-items-center gap-2 gap-md-3">
            <div className="d-none d-lg-inline">
              <b2x.FormGroup
                className="quantity-container"
                {...fieldsHelper.quantity.formGroup}
                label={undefined}
                noMarginBottom
              >
                <div className="d-flex">
                  <div className="btn-container" style={{ flex: '0 0 auto' }}>
                    <b2x.Button
                      className="px-0"
                      variant="gray-200"
                      {...fieldsHelper.buttons.quantity.decrease}
                      iconStart={{ name: 'minus', size: 14 }}
                      label={undefined}
                    />
                  </div>
                  <div className="px-2" style={{ flex: '0 0 auto', width: 45 }}>
                    <b2x.NumberInput
                      className="border-0 text-center fw-bold bg-transparent px-0 py-2"
                      {...fieldsHelper.quantity.numberInput}
                      placeholder={undefined}
                      readOnly
                    />
                  </div>
                  <div className="btn-container" style={{ flex: '0 0 auto' }}>
                    <b2x.Button
                      className="px-0"
                      variant="gray-200"
                      {...fieldsHelper.buttons.quantity.increase}
                      iconStart={{ name: 'plus', size: 14 }}
                      label={undefined}
                    />
                  </div>
                </div>
              </b2x.FormGroup>
            </div>

            {priceHelper && (
              <div className="d-lg-none">
                <b2x.PriceBlock
                  classNames={{ specialPrice: 'fw-bold text-red' }}
                  gap={2}
                  hiddenDiscountPercentage
                  priceHelper={priceHelper}
                />
                {priceHelper.perUnit?.price && (
                  <div className="extra-small">
                    {`(${t('misc.pricePerUnit', {
                      measurementUnit: priceHelper.perUnit.measurement,
                      price: b2x.formatCurrency(priceHelper.perUnit.price),
                    })})`}
                  </div>
                )}
              </div>
            )}

            <div className="ms-auto">
              <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
                fieldsHelper={fieldsHelper}
                selectedSku={selectedSku}
                submitButton={{
                  className: 'add-to-cart-button px-2 px-md-4 me-2',
                  iconStart: { name: 'cart', size: 24 },
                  label: undefined,
                }}
              />
            </div>
          </div>
        ) : (
          <div className="d-grid my-2">
            <Button className="btn-sm" disabled label={t('misc.productNotAvailable')} variant="outline-primary" />
          </div>
        )}
      </b2x.EqualHeightElement>
      {b2x.appConfig.enableBestPrice && priceHelper && (
        <div className="extra-small lh-1 mt-2">
          <b2x.BestPrice priceHelper={priceHelper} />
        </div>
      )}
    </div>
  );
};
